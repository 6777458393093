import { Grid, makeStyles } from '@material-ui/core';
import { useState, useRef, useEffect, useCallback } from 'react';
import content from '../../../../content/showcase/qap/views.json';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, largeText, smallSubtitle, smallText } from '../../../../styles/dimensions';
import RigrafExpanded from '../../../../fonts/rigraf/expanded/rigrafExpanded';
import { StaticImage } from 'gatsby-plugin-image';
import { ScrollEvent, useScrollContext } from '../../../../hooks/useScrollContext';
import MetroSans from '../../../../fonts/metro-sans/metroSans';

interface ClassProps {
	carouselScrollWidth: number | string;
}

const Views = () => {
	const [carouselScrollWidth, setCarouselScrollWidth] = useState<number | string>('auto');

	const carouselRef = useRef<HTMLDivElement>(null);
	const carouselContainerRef = useRef<HTMLDivElement>(null);
	const scroll = useScrollContext();

	const classes = useClasses({ carouselScrollWidth });

	useEffect(() => {
		if (typeof window !== 'undefined' && scroll) {
			setCarouselScrollWidth(carouselRef.current.scrollWidth);

			window.addEventListener('resize', handleResize, false);

			scroll.on('scroll', (event: ScrollEvent) => {
				if (event.scroll.y < carouselContainerRef.current.offsetTop) {
					carouselRef.current.scrollLeft = 0;
				} else if (event.scroll.y > carouselContainerRef.current.offsetTop + 1872) {
					carouselRef.current.scrollLeft = carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
				} else {
					carouselRef.current.scrollLeft = event.scroll.y - carouselContainerRef.current.offsetTop;
				}

				if (scroll.scroll.windowWidth < 600) {
					if (
						event.scroll.y <
						carouselContainerRef.current.offsetTop -
							scroll.scroll.windowHeight / 2 +
							carouselContainerRef.current.children[0].clientHeight / 2
					) {
						if (carouselRef.current.style.position !== 'static') {
							carouselRef.current.style.position = 'static';
							carouselRef.current.style.transform = 'translateY(0px)';
						}
					} else if (
						event.scroll.y >
						carouselContainerRef.current.offsetTop +
							carouselRef.current.scrollWidth -
							scroll.scroll.windowHeight / 2 +
							carouselContainerRef.current.children[0].clientHeight / 2
					) {
						if (carouselRef.current.style.position !== 'static') {
							carouselRef.current.style.position = 'static';
							carouselRef.current.style.transform = `translateY(${carouselRef.current.scrollWidth}px)`;
						}
					} else {
						if (carouselRef.current.style.position !== 'fixed') {
							carouselRef.current.style.position = 'fixed';
							carouselRef.current.style.transform = 'translateY(-50%)';
						}
					}
				}
			});
		}
	}, [scroll, classes]);

	const handleResize = useCallback(() => {
		if (carouselRef.current?.scrollWidth) {
			setCarouselScrollWidth(carouselRef.current.scrollWidth);
		}
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<span className={classes.viewsLabel} data-scroll data-scroll-speed="2">
					{content.viewsLabel}
				</span>

				<ul className={classes.list} data-scroll data-scroll-speed="2">
					{content.views.map((item, key) => (
						<li key={key} className={classes.listItems}>
							{item}
						</li>
					))}
				</ul>

				<Grid container>
					<Grid item md={1}></Grid>

					<Grid item xs={12} md={10}>
						<StaticImage
							src="../../../../images/showcase/qap/home.png"
							alt="Home screen"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.homeImage}
							data-scroll
							data-scroll-speed="2"
						/>

						<div className={classes.carouselContainer} ref={carouselContainerRef} id="carousel-container">
							<div
								ref={carouselRef}
								className={classes.carousel}
								data-scroll
								data-scroll-sticky
								data-scroll-target="#carousel-container"
								data-scroll-offset="-200">
								<div className={classes.spacer}></div>
								<StaticImage
									src="../../../../images/showcase/qap/zones.png"
									alt="Zone overview screen"
									layout="constrained"
									loading="lazy"
									placeholder="blurred"
									objectFit="contain"
									className={classes.carouselImage}
								/>
								<StaticImage
									src="../../../../images/showcase/qap/summary.png"
									alt="Summary"
									layout="constrained"
									loading="lazy"
									placeholder="blurred"
									objectFit="contain"
									className={classes.carouselImage}
								/>
								<StaticImage
									src="../../../../images/showcase/qap/contracts.png"
									alt="Contact list screen"
									layout="constrained"
									loading="lazy"
									placeholder="blurred"
									objectFit="contain"
									className={classes.carouselImage}
								/>
								<div className={classes.spacer}></div>
							</div>
						</div>

						<StaticImage
							src="../../../../images/showcase/qap/expenses.png"
							alt="Expenses screen"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.expensesImage}
							data-scroll
							data-scroll-speed="2"
						/>

						<div className={classes.problemGoalContainer} data-scroll data-scroll-speed="1">
							<div className={classes.problemGoalWrapper}>
								<h5 className={classes.problemGoalTitle}>{content.problem.title}</h5>
								<p className={classes.problemGoalDescription}>{content.problem.description}</p>
							</div>
							<div className={classes.problemGoalWrapper}>
								<h5 className={classes.problemGoalTitle}>{content.goal.title}</h5>
								<p className={classes.problemGoalDescription}>{content.goal.description}</p>
							</div>
						</div>

						<StaticImage
							src="../../../../images/showcase/qap/dashboard.png"
							alt="Dashboard"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							objectFit="contain"
							className={classes.dashboardImage}
							data-scroll
							data-scroll-speed="1"
						/>
					</Grid>

					<Grid item md={1}></Grid>
				</Grid>
			</div>

			<StaticImage
				src="../../../../images/showcase/qap/login.jpg"
				alt="QAP showcase"
				layout="fullWidth"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="60% 45%"
				className={classes.showcaseImage}
			/>
		</section>
	);
};

export default Views;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.backgroundDarkGray,
		paddingTop: '3em',
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media(max-width: 959px)': {
			padding: '0 1em',
		},
	},
	viewsLabel: {
		display: 'block',
		fontFamily: Rigraf.extraLight,
		...smallText,
		letterSpacing: '0.09375em',
		color: colors.white,
		'@media (min-width: 960px) and (max-width: 1231px)': {
			padding: '0 1em',
		},
	},
	list: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		color: colors.white,
		fontFamily: RigrafExpanded.semiBold,
		...smallSubtitle,
		marginTop: '0.75em',
		marginBottom: '2.25em',
		'@media (min-width: 960px) and (max-width: 1231px)': {
			padding: '0 0.5em',
		},
		'@media (max-width: 599px)': {
			...largeText,
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	listItems: {
		fontFamily: RigrafExpanded.semiBold,
		...smallSubtitle,
		'@media (max-width: 959px)': {
			...largeText,
		},
		'@media (max-width: 599px)': {
			...largeText,
			margin: '0.16666667em 0',
		},
	},
	homeImage: {
		marginBottom: '4em',
		'@media (min-width: 600px) and (min-height: 900px)': {
			paddingTop: '5vh',
		},
	},
	carouselContainer: {
		marginBottom: 'calc(9.5em + 200px + 50vh - 444px)',
		'@media (min-width: 600px)': {
			height: '1872px',
			paddingTop: 'calc(50vh - 444px + 84px)',
			boxSizing: 'border-box',
		},
		'@media (max-width: 599px)': {
			marginBottom: 'calc(9.5em + 300px)',
			height: (props: ClassProps) =>
				typeof props.carouselScrollWidth === 'number'
					? `${props.carouselScrollWidth}px`
					: props.carouselScrollWidth,
		},
		'@media (max-width: 549px)': {
			marginBottom: 'calc(9.5em + 260px)',
		},
		'@media (max-width: 499px)': {
			marginBottom: 'calc(9.5em + 220px)',
		},
		'@media (max-width: 449px)': {
			marginBottom: 'calc(9.5em + 180px)',
		},
		'@media (max-width: 399px)': {
			marginBottom: 'calc(9.5em + 160px)',
		},
		'@media (max-width: 349px)': {
			marginBottom: 'calc(9.5em + 120px)',
		},
		'@media (min-width: 600px) and (min-height: 750px)': {
			marginTop: 'calc(-50vh + 444px - 84px)',
		},
	},
	carousel: {
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		margin: '0 -1em',
		'@media (min-width: 600px)': {
			position: 'absolute',
			zIndex: 0,
			left: 0,
		},
		'@media (max-width: 599px)': {
			top: '50vh',
			left: '16px',
			right: '16px',
		},
	},
	carouselImage: {
		padding: '0 1em',
	},
	expensesImage: {
		marginBottom: '7.5em',
		'@media (max-width: 599px)': {
			marginBottom: '5em',
		},
		'@media (min-width: 600px) and (min-height: 900px)': {
			paddingTop: '5vh',
		},
	},
	spacer: {
		'@media (min-width: 600px) and (max-width: 959px)': {
			width: '1em',
			height: '100%',
			display: 'inline-block',
		},
		'@media (min-width: 960px) and (max-width: 1200px)': {
			width: '8.33333333vw',
			height: '100%',
			display: 'inline-block',
		},
		'@media (min-width: 1200px)': {
			width: 'calc(50vw - 600px + (0.08333333 * 1200px))',
			height: '100%',
			display: 'inline-block',
		},
	},
	problemGoalContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			marginBottom: '4em',
		},
	},
	problemGoalWrapper: {
		'@media (min-width: 600px)': {
			width: 'calc(50% - 1em)',
		},
	},
	problemGoalTitle: {
		letterSpacing: '0.075em',
		color: colors.white,
		fontFamily: Rigraf.regular,
		marginTop: 0,
		marginBottom: '1.5em',
	},
	problemGoalDescription: {
		...smallText,
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
	},
	dashboardImage: {
		marginBottom: '2.25em',
		'@media (max-width: 599px)': {
			marginBottom: '4em',
		},
	},
	showcaseImage: {
		maxHeight: '100vh',
	},
});

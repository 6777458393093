import { makeStyles } from '@material-ui/core';
import Layout from '../../components/layout';
import IntroLayout from '../../components/sections/showcase/introLayout';
import Views from '../../components/sections/showcase/qap/views';
import SEO from '../../components/seo';
import colors from '../../styles/colors';
import content from '../../content/showcase/qap/intro.json';
// @ts-ignore
import video from '../../videos/qap.mp4';
import { descriptions, titles } from '../../consts/seo';

const Qap = () => (
	<Layout rootClassName={useClasses({}).root}>
		<SEO title={titles.showcase.QAP} description={descriptions.showcase.QAP} />

		<IntroLayout content={content} devices={{ desktop: true, tablet: true }} video={video} />
		<Views />
	</Layout>
);

export default Qap;

const useClasses = makeStyles({
	root: {
		backgroundColor: colors.backgroundDarkGray,
	},
});
